<nav>
    <msc-common-tree [data]="data">
        <ng-template let-datum="datum">
            <a routerLinkActive="is-active" [routerLinkActiveOptions]="{ exact: false }" [routerLink]="datum?.value?.routerLink">
                <ng-container *ngIf="datum?.value?.icon">
                    <i class="icon icon-{{ datum?.value?.icon }}"></i>
                </ng-container>
                <span>{{ datum?.label }}</span>
                <ng-container *ngIf="datum?.value?.isNew">
                    <span class="tag">{{ "words.new" | translate }}</span>
                </ng-container>
            </a>
        </ng-template>
    </msc-common-tree>
</nav>
