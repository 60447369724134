<div class="body  items-start gap-0">
    <msc-common-nav [data]="nav"></msc-common-nav>
    <section>
        <router-outlet></router-outlet>
    </section>
</div>

<style>
    @media screen and (max-width: 768px) {
        .body {
            width: 70vw;
            max-width: max-content;
        }
    }
</style>
