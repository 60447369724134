import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    ApplicationResolver,
    CompanyGuardCheck,
    CompanyResolver,
    CompanyRoleResolver,
    IntegrationResolver,
    ProviderResolver,
    ProvidersResolver,
    SidebarResolver,
    UserResolver,
} from '../../app.resolver';

import { CanDeactivateGuard } from '../../guards';

import {
    ApplicationFeatureResolver,
    BillingInvoiceResolver,
    BillingProductResolver,
    CampResolver,
    CompanyColorResolver,
    CompanyExperienceResolver,
    CompanyExperienceResourcesResolver,
    CompanySettingsResolver,
    CompanyUrlResolver,
    CompanyUserResolver,
    ContentResolver,
    CorrectionResolver,
    DetailStatsResolver,
    GroupResolver,
    IntegrationAvailableProvidersResolver,
    IntegrationProviderCollectionResolver,
    IntegrationProviderCollectionsResolver,
    IntegrationProviderMappingCategoriesResolver,
    IntegrationProviderExceptionResolver,
    IntegrationProviderMappingSkillsResolver,
    IntegrationProviderResolver,
    IntegrationProviderRuleResolver,
    IntegrationProviderRulesResolver,
    IntegrationProvidersResolver,
    KnowledgeContentsResolver,
    KnowledgeResolver,
    KnowledgeSummaryResolver,
    MemberResolver,
    SubscriptionResolver,
    TemplateResolver,
    UserContentsStatsResolver,
    ProviderAllowGuard,
} from './organize.resolver';

import { HubComponent } from '../hub/hub.component';

import { OrganizeComponent } from './organize.component';

import { OrganizeStatisticAdoptionComponent } from './statistic/adoption/adoption.component';
import { OrganizeStatisticComponent } from './statistic/statistic.component';
import { OrganizeStatisticProvidersComponent } from './statistic/providers/providers.component';
import { OrganizeStatisticProvidersDetailComponent } from './statistic/providers/detail/detail.component';
import { OrganizeStatisticProvidersListComponent } from './statistic/providers/list/list.component';

import { OrganizeUserComponent } from './user/user.component';
import { OrganizeUserConfigureComponent } from './user/watch/configure/configure.component';
import { OrganizeUserInvitationComponent } from './user/watch/invitation/invitation.component';
import { OrganizeUserUpdateComponent } from './user/watch/update/update.component';
import { OrganizeUserListComponent } from './user/list/list.component';
import { OrganizeUserWatchComponent } from './user/watch/watch.component';

import { OrganizeExperienceComponent } from './experience/experience.component';
import { OrganizeExperienceListComponent } from './experience/list/list.component';
import { OrganizeExperienceWatchCatalogueComponent } from './experience/watch/catalogue/catalogue.component';
import { OrganizeExperienceWatchComponent } from './experience/watch/watch.component';
import { OrganizeExperienceWatchDashboardComponent } from './experience/watch/dashboard/dashboard.component';
import { OrganizeExperienceWatchLoginComponent } from './experience/watch/login/login.component';
import { OrganizeExperienceWatchMailComponent } from './experience/watch/mail/mail.component';
import { OrganizeExperienceWatchMailCreateComponent } from './experience/watch/mail/mail-create/mail-create.component';
import { OrganizeExperienceWatchMailDetailsComponent } from './experience/watch/mail/mail-details/mail-details.component';
import { OrganizeExperienceWatchMailEditComponent } from './experience/watch/mail/mail-edit/mail-edit.component';
import { OrganizeExperienceWatchMailListComponent } from './experience/watch/mail/mail-list/mail-list.component';
import { OrganizeExperienceWatchThemeComponent } from './experience/watch/theme/theme.component';
import { OrganizeExperienceWatchUrlComponent } from './experience/watch/url/url.component';
import { OrganizeExperienceWatchUrlCreateComponent } from './experience/watch/url/url-create/url-create.component';
import { OrganizeExperienceWatchUrlDetailsComponent } from './experience/watch/url/url-details/url-details.component';
import { OrganizeExperienceWatchUrlListComponent } from './experience/watch/url/url-list/url-list.component';

import { OrganizeCompanyComponent } from './company/company.component';
import { OrganizeCompanyInvoiceComponent } from './company/invoice/invoice.component';
import { OrganizeCompanySettingsComponent } from './company/settings/settings.component';
import { OrganizeCompanySubscriptionComponent } from './company/subscription/subscription.component';

import { OrganizeIntegrationComponent } from './integration/integration.component';
import { OrganizeIntegrationListComponent } from './integration/list/list.component';
import { OrganizeIntegrationsProvidersComponent } from './integration/providers/providers.component';
import { OrganizeIntegrationWatchCatalogueExportComponent } from './integration/watch/catalogue-export/catalogue-export.component';
import { OrganizeIntegrationWatchComponent } from './integration/watch/watch.component';
import { OrganizeIntegrationWatchExceptionComponent } from './integration/watch/skills/exception/exception.component';
import { OrganizeIntegrationWatchMappingComponent } from './integration/watch/skills/mapping/mapping.component';
import { OrganizeIntegrationWatchRulesComponent } from './integration/watch/rules/rules.component';
import { OrganizeIntegrationWatchRulesEditComponent } from './integration/watch/rules/edit/edit.component';
import { OrganizeIntegrationWatchSettingsComponent } from './integration/watch/settings/settings.component';
import { OrganizeIntegrationWatchStatsComponent } from './integration/watch/stats/stats.component';

import { OrganizeKnowledgeComponent } from './knowledge/knowledge.component';
import { OrganizeKnowledgeListComponent } from './knowledge/list/list.component';
import { OrganizeKnowledgeWatchComponent } from './knowledge/watch/watch.component';
import { OrganizeKnowledgeWatchContentComponent } from './knowledge/watch/content/content.component';
import { OrganizeKnowledgeWatchSummaryComponent } from './knowledge/watch/summary/summary.component';

import { CampCommunityComponent } from './camp/community/community.component';
import { CampComponent } from './camp/camp.component';
import { CampCorrectionComponent } from './camp/correction/correction.component';
import { CampMemberComponent } from './camp/member/member.component';
import { CampSettingsComponent } from './camp/settings/settings.component';
import { CampSettingsFeaturesComponent } from './camp/settings/settings-features/settings-features.component';
import { CampSettingsGeneralComponent } from './camp/settings/settings-general/settings-general.component';
import { CampSettingsNotificationsComponent } from './camp/settings/settings-notifications/settings-notifications.component';
import { CampStatsAdoptionComponent } from './camp/stats/stats-adoption/stats-adoption.component';
import { CampStatsComponent } from './camp/stats/stats.component';
import { CampStatsContentComponent } from './camp/stats/stats-content/stats-content.component';
import { CampStatsDetailComponent } from './camp/stats/stats-detail/stats-detail.component';
import { CampStatsProvidersComponent } from './camp/stats/stats-providers/stats-providers.component';
import { CampStatsProvidersDetailComponent } from './camp/stats/stats-providers/detail/detail.component';
import { CampStatsProvidersListComponent } from './camp/stats/stats-providers/list/list.component';
import { CampStatsTemplateComponent } from './camp/stats/stats-template/stats-template.component';
import { CampStatsUserComponent } from './camp/stats/stats-user/stats-user.component';
import { CampSubscriptionComponent } from './camp/subscription/subscription.component';
import { CampTemplateComponent } from './camp/template/template.component';
import { CampTimelineComponent } from './camp/timeline/timeline.component';
import { OrganizeCompanyAdvancedSettingsComponent } from './company/advanced/advanced.component';
import { CampStatsExportsComponent } from './camp/stats/stats-exports/stats-exports.component';
import { OrganizeExportsComponent } from './statistic/exports/exports.component';
import { WatchSettingsComponent } from './experience/watch/settings/settings.component';
import { DashboardComponent } from './statistic/dashboard/dashboard.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { GeneratedCertificatesComponent } from './certificates/tabs/generated-certificates/generated-certificates.component';
import { TransactionsComponent } from './certificates/tabs/transactions/transactions.component'; // Temporary
import { WatchCertificatesComponent } from './experience/watch/certificates/certificates.component';
import { OnDemandComponent } from './on-demand/on-demand.component';

export const routes: Routes = [
    {
        path: ':slug',
        component: OrganizeComponent,
        canActivate: [CompanyGuardCheck],
        data: { breadcrumb: { skip: true } },
        resolve: {
            me: UserResolver,
            sidebar: SidebarResolver,
            company: CompanyResolver,
            access: CompanyRoleResolver,
            applications: ApplicationResolver,
        },
        children: [
            {
                path: '',
                redirectTo: 'camp',
                pathMatch: 'full',
            },
            {
                path: 'camp',
                component: CampComponent,
            },
            {
                path: 'camp/:campId',
                component: CampComponent,
                data: { breadcrumb: { alias: 'cct' } },
                resolve: {
                    camp: CampResolver,
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'contents',
                        pathMatch: 'full',
                    },
                    {
                        path: 'contents',
                        component: HubComponent,
                        data: { breadcrumb: { skip: true } },
                        children: [
                            {
                                path: '',
                                redirectTo: 'template',
                                pathMatch: 'full',
                            },
                            {
                                path: 'template',
                                component: CampTemplateComponent,
                                resolve: {
                                    contents: ContentResolver,
                                },
                            },
                            {
                                path: 'template/:slug',
                                component: CampTemplateComponent,
                                resolve: {
                                    templates: TemplateResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: 'subscription',
                        component: CampSubscriptionComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            subscriptions: SubscriptionResolver,
                        },
                    },
                    {
                        path: 'correction',
                        component: CampCorrectionComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            corrections: CorrectionResolver,
                        },
                    },
                    {
                        path: 'member',
                        component: CampMemberComponent,
                        data: { breadcrumb: { skip: true } },
                        resolve: {
                            members: MemberResolver,
                        },
                    },
                    {
                        path: 'stats',
                        component: CampStatsComponent,
                        data: { breadcrumb: { skip: true } },
                        children: [
                            {
                                path: '',
                                redirectTo: 'content',
                                pathMatch: 'full',
                            },
                            {
                                path: 'content',
                                component: CampStatsContentComponent,
                            },
                            {
                                path: 'details/:context/:contextId',
                                component: CampStatsComponent,
                                children: [
                                    {
                                        path: '',
                                        component: CampStatsDetailComponent,
                                    },
                                    {
                                        path: 'user/:userId',
                                        component: CampStatsComponent,
                                        children: [
                                            {
                                                path: '',
                                                component:
                                                    CampStatsUserComponent,
                                            },
                                            {
                                                path: 'template/:templateId',
                                                component:
                                                    CampStatsTemplateComponent,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'adoption',
                                component: CampStatsAdoptionComponent,
                            },
                            {
                                path: 'providers',
                                component: CampStatsProvidersComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'compare',
                                        pathMatch: 'full',
                                    },
                                    {
                                        path: 'compare',
                                        component:
                                            CampStatsProvidersListComponent,
                                        resolve: {
                                            providers: ProvidersResolver,
                                        },
                                    },
                                    {
                                        path: 'detail/:providerId',
                                        component:
                                            CampStatsProvidersDetailComponent,
                                        resolve: {
                                            provider: ProviderResolver,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'exports',
                                component: CampStatsExportsComponent,
                            },
                        ],
                    },
                    {
                        path: 'timeline',
                        data: { breadcrumb: { skip: true } },
                        component: CampTimelineComponent,
                    },
                    {
                        path: 'community',
                        component: CampCommunityComponent,
                        data: { breadcrumb: { skip: true } },
                    },
                    {
                        path: 'settings',
                        component: CampSettingsComponent,
                        data: { breadcrumb: { skip: true } },
                        children: [
                            {
                                path: '',
                                redirectTo: 'general',
                                pathMatch: 'full',
                            },
                            {
                                path: 'general',
                                component: CampSettingsGeneralComponent,
                            },
                            {
                                path: 'features',
                                component: CampSettingsFeaturesComponent,
                            },
                            {
                                path: 'notifications',
                                component: CampSettingsNotificationsComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'users',
                component: OrganizeUserComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: OrganizeUserListComponent,
                    },
                    {
                        path: 'action',
                        component: OrganizeUserWatchComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'invitation',
                                pathMatch: 'full',
                            },
                            {
                                path: 'invitation',
                                canDeactivate: [CanDeactivateGuard],
                                component: OrganizeUserInvitationComponent,
                            },
                            {
                                path: 'configure',
                                canDeactivate: [CanDeactivateGuard],
                                component: OrganizeUserConfigureComponent,
                            },
                            {
                                path: 'update',
                                canDeactivate: [CanDeactivateGuard],
                                component: OrganizeUserUpdateComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'experiences',
                component: OrganizeExperienceComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: OrganizeExperienceListComponent,
                        // resolve: {
                        //     experiences: CompanyExperienceResolver
                        // }
                    },
                    {
                        path: ':experienceId',
                        component: OrganizeExperienceWatchComponent,
                        resolve: {
                            experience: CompanyExperienceResolver,
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'dashboard',
                                pathMatch: 'full',
                            },
                            {
                                path: 'dashboard',
                                component:
                                    OrganizeExperienceWatchDashboardComponent,
                                resolve: {
                                    resources:
                                        CompanyExperienceResourcesResolver,
                                },
                            },
                            {
                                path: 'catalogue',
                                component:
                                    OrganizeExperienceWatchCatalogueComponent,
                            },
                            {
                                path: 'mail',
                                component: OrganizeExperienceWatchMailComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'list',
                                        pathMatch: 'full',
                                    },
                                    {
                                        path: 'create',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchMailCreateComponent,
                                    },
                                    {
                                        path: 'details/:senderId',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchMailDetailsComponent,
                                    },
                                    {
                                        path: 'edit/:senderId',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchMailEditComponent,
                                    },
                                    {
                                        path: 'list',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchMailListComponent,
                                    },
                                ],
                            },
                            {
                                path: 'login',
                                component:
                                    OrganizeExperienceWatchLoginComponent,
                            },
                            {
                                path: 'theme',
                                component:
                                    OrganizeExperienceWatchThemeComponent,
                                resolve: {
                                    themes: CompanyColorResolver,
                                },
                            },
                            {
                                path: 'url',
                                component: OrganizeExperienceWatchUrlComponent,
                                children: [
                                    {
                                        path: '',
                                        redirectTo: 'list',
                                        pathMatch: 'full',
                                    },
                                    {
                                        path: 'create',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchUrlCreateComponent,
                                    },
                                    {
                                        path: 'details/:urlId',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchUrlDetailsComponent,
                                    },
                                    {
                                        path: 'list',
                                        canDeactivate: [CanDeactivateGuard],
                                        component:
                                            OrganizeExperienceWatchUrlListComponent,
                                    },
                                ],
                            },
                            {
                                path: 'settings',
                                component: WatchSettingsComponent,
                            },
                            {
                                path: 'certificates',
                                component: WatchCertificatesComponent,
                                children: [
                                    {
                                        path: 'generated',
                                        canDeactivate: [CanDeactivateGuard],
                                        component: WatchCertificatesComponent,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'statistics',
                component: OrganizeStatisticComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        redirectTo: 'adoption',
                        pathMatch: 'full',
                    },
                    {
                        path: 'adoption',
                        component: OrganizeStatisticAdoptionComponent,
                    },
                    {
                        path: 'providers',
                        component: OrganizeStatisticProvidersComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'compare',
                                pathMatch: 'full',
                            },
                            {
                                path: 'compare',
                                component:
                                    OrganizeStatisticProvidersListComponent,
                                resolve: {
                                    providers: ProvidersResolver,
                                },
                            },
                            {
                                path: 'detail/:providerId',
                                component:
                                    OrganizeStatisticProvidersDetailComponent,
                                resolve: {
                                    provider: ProviderResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: 'exports',
                        component: OrganizeExportsComponent,
                    },
                    {
                        path: 'dashboard',
                        component: DashboardComponent,
                    },
                ],
            },
            {
                path: 'marketplace',
                data: { breadcrumb: { skip: true } },
                loadChildren: () =>
                    import('../../modules/organize/organize.module').then(
                        (m) => m.Organize2Module
                    ),
            },
            {
                path: 'company',
                component: OrganizeCompanyComponent,
                data: { breadcrumb: { skip: true } },
                resolve: {
                    settings: CompanySettingsResolver,
                },
                children: [
                    {
                        path: '',
                        redirectTo: 'settings',
                        pathMatch: 'full',
                    },
                    {
                        path: 'settings',
                        children: [
                            {
                                path: '',
                                redirectTo: 'general',
                                pathMatch: 'full',
                            },
                            {
                                path: 'general',
                                component: OrganizeCompanySettingsComponent,
                            },
                            {
                                path: 'advanced',
                                component:
                                    OrganizeCompanyAdvancedSettingsComponent,
                            },
                        ],
                    },
                    {
                        path: 'subscription',
                        component: OrganizeCompanySubscriptionComponent,
                        resolve: {
                            products: BillingProductResolver,
                        },
                    },
                    {
                        path: 'invoice',
                        component: OrganizeCompanyInvoiceComponent,
                        resolve: {
                            invoices: BillingInvoiceResolver,
                        },
                    },
                ],
            },
            {
                path: 'integrations',
                component: OrganizeIntegrationComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        redirectTo: 'tools',
                        pathMatch: 'full',
                    },
                    {
                        path: 'tools',
                        component: OrganizeIntegrationListComponent,
                        resolve: {
                            integrations: IntegrationResolver,
                        },
                    },
                    {
                        path: 'providers',
                        component: HubComponent,
                        runGuardsAndResolvers: 'always',
                        resolve: {
                            providers: IntegrationProvidersResolver,
                            availableProviders:
                                IntegrationAvailableProvidersResolver,
                        },
                        children: [
                            {
                                path: '',
                                component:
                                    OrganizeIntegrationsProvidersComponent,
                            },
                            {
                                path: ':providerId',
                                component: OrganizeIntegrationWatchComponent,
                                resolve: {
                                    integration: IntegrationProviderResolver,
                                    collections:
                                        IntegrationProviderCollectionsResolver,
                                },
                                children: [
                                    {
                                        path: 'settings',
                                        runGuardsAndResolvers: 'always',
                                        component:
                                            OrganizeIntegrationWatchSettingsComponent,
                                    },
                                    {
                                        path: 'skills',
                                        children: [
                                            {
                                                path: '',
                                                redirectTo: 'mapping',
                                                pathMatch: 'full',
                                            },
                                            {
                                                path: 'mapping',
                                                component:
                                                    OrganizeIntegrationWatchMappingComponent,
                                                runGuardsAndResolvers: 'always',
                                                resolve: {
                                                    categories:
                                                        IntegrationProviderMappingCategoriesResolver,
                                                },
                                            },
                                            {
                                                path: 'exception',
                                                component:
                                                    OrganizeIntegrationWatchExceptionComponent,
                                                resolve: {
                                                    contents:
                                                        IntegrationProviderExceptionResolver,
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        path: 'collections',
                                        component: HubComponent,
                                        children: [
                                            {
                                                path: ':collectionId',
                                                resolve: {
                                                    collection:
                                                        IntegrationProviderCollectionResolver,
                                                },
                                                children: [
                                                    {
                                                        path: 'stats',
                                                        component:
                                                            OrganizeIntegrationWatchStatsComponent,
                                                    },
                                                    {
                                                        path: 'catalogue-export',
                                                        component:
                                                            OrganizeIntegrationWatchCatalogueExportComponent,
                                                    },
                                                    {
                                                        path: 'rules',
                                                        resolve: {
                                                            rules: IntegrationProviderRulesResolver,
                                                        },
                                                        runGuardsAndResolvers:
                                                            'always',
                                                        canActivate: [
                                                            ProviderAllowGuard,
                                                        ],
                                                        children: [
                                                            {
                                                                path: '',
                                                                component:
                                                                    OrganizeIntegrationWatchRulesComponent,
                                                            },
                                                            {
                                                                path: 'edit',
                                                                children: [
                                                                    {
                                                                        path: ':ruleId',
                                                                        component:
                                                                            OrganizeIntegrationWatchRulesEditComponent,
                                                                        canDeactivate:
                                                                            [
                                                                                CanDeactivateGuard,
                                                                            ],
                                                                        resolve:
                                                                            {
                                                                                rule: IntegrationProviderRuleResolver,
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: 'create',
                                                                canDeactivate: [
                                                                    CanDeactivateGuard,
                                                                ],
                                                                component:
                                                                    OrganizeIntegrationWatchRulesEditComponent,
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'knowledge',
                component: OrganizeKnowledgeComponent,
                data: { breadcrumb: { skip: true } },
                children: [
                    {
                        path: '',
                        component: OrganizeKnowledgeListComponent,
                        resolve: {
                            skills: KnowledgeResolver,
                        },
                    },
                    {
                        path: ':skillId',
                        component: OrganizeKnowledgeWatchComponent,
                        resolve: {
                            skill: KnowledgeSummaryResolver,
                        },
                        children: [
                            {
                                path: '',
                                redirectTo: 'summary',
                                pathMatch: 'full',
                            },
                            {
                                path: 'summary',
                                component:
                                    OrganizeKnowledgeWatchSummaryComponent,
                            },
                            {
                                path: 'contents',
                                component:
                                    OrganizeKnowledgeWatchContentComponent,
                                resolve: {
                                    contents: KnowledgeContentsResolver,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'certificates',
                component: CertificatesComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'certificate',
                    },
                    {
                        path: 'certificate',
                        component: GeneratedCertificatesComponent,
                    },
                    {
                        path: 'transactions',
                        component: TransactionsComponent,
                    },
                ],
            },
            
            {
                path: 'ondemand',
                component: OnDemandComponent,
                data: { breadcrumb: { skip: true } },
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        ApplicationFeatureResolver,
        ApplicationResolver,
        BillingInvoiceResolver,
        BillingProductResolver,
        CampResolver,
        CanDeactivateGuard,
        CompanyColorResolver,
        CompanyExperienceResolver,
        CompanyExperienceResourcesResolver,
        CompanyResolver,
        CompanyRoleResolver,
        CompanySettingsResolver,
        CompanyUrlResolver,
        CompanyUserResolver,
        ContentResolver,
        CorrectionResolver,
        DetailStatsResolver,
        GroupResolver,
        IntegrationAvailableProvidersResolver,
        IntegrationProviderCollectionResolver,
        IntegrationProviderCollectionsResolver,
        IntegrationProviderMappingCategoriesResolver,
        IntegrationProviderExceptionResolver,
        IntegrationProviderMappingSkillsResolver,
        IntegrationProviderResolver,
        IntegrationProviderRuleResolver,
        IntegrationProviderRulesResolver,
        IntegrationProvidersResolver,
        IntegrationResolver,
        KnowledgeContentsResolver,
        KnowledgeResolver,
        KnowledgeSummaryResolver,
        MemberResolver,
        ProviderResolver,
        ProvidersResolver,
        SidebarResolver,
        SubscriptionResolver,
        TemplateResolver,
        UserContentsStatsResolver,
        UserResolver,
        ProviderAllowGuard,
    ],
})
export class OrganizeRoutingModule {}
