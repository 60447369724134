<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3 flex justify-between">
                <h3 class="title-table">
                    {{ "organize.certifacates.nav.transactions" | translate }}
                </h3>
                <div class="flex flex-col">
                    <!--
                    <msc-button
                    class="msc-button--primary ml-auto mb-3"
                    icon="icon-plus"
                    palette="secondary"
                    (click)="openModalAdd()"
                >
                    {{ "words.add" | translate }}
                </msc-button>-->

                    <div class="flex justify-content-end mr-3">
                        <div class="flex gap-4 justify-content-end align-center">
                            <msc-button class="msc-button--primary" icon="icon-trash" palette="secondary"
                                (click)="clearFilters()" [tooltip]="'words.reset-filters' | translate"
                                *ngIf="currentSelectedFilter != undefined">
                            </msc-button>
                            <div class="flex align-center w-50 pl-6 btn-filter">
                                <p>{{ "words.filters" | translate }}</p>
                                <msc-common-select class="text-sm w-full" [data]="filters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{
                                                    currentSelectedFilter?.key
                                                    }}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="
                                                onSelectType(datum, 'filter')
                                            ">
                                            <span>{{ datum.key }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>
                            <div class="scale-50">
                                <msc-common-spinner *ngIf="isLoadingFilter"></msc-common-spinner>
                            </div>
                            <div class="w-52 pl-6" *ngIf="
                                    currentSelectedFilter != undefined &&
                                    !isLoadingFilter &&
                                    currentSelectedFilter?.value != 'email'
                                ">
                                <msc-common-select class="text-sm w-full" [data]="filteredFilters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{
                                                    currentSelectedFilteredFilters?.title
                                                    | translate
                                                    }}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="
                                                onSelectType(
                                                    datum,
                                                    'filterSelect'
                                                )
                                            ">
                                            <span>{{
                                                datum.title | translate
                                                }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>

                            <msc-input [placeholder]="'words.email' | translate" class="w-2/4 pr-3"
                                [(ngModel)]="inputFilter" *ngIf="
                                    currentSelectedFilter?.value == 'email' &&
                                    !isLoadingFilter
                                "></msc-input>

                            <msc-button class="msc-button--primary ml-auto" icon="icon-search" palette="secondary"
                                (click)="filter()" *ngIf="
                                    currentSelectedFilteredFilters !=
                                        undefined || inputFilter != ''
                                ">
                                {{ "placeholder.search" | translate }}
                            </msc-button>

                            <msc-button class="btn-refresh mr-3" icon="icon-refresh" palette="secondary"
                                (click)="clearFilters()" [tooltip]="'words.refresh' | translate" [disabled]="isLoading">
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- tabla -->
            <div class="table-container overflow-x-auto bg-white border border-gray-50 shadow-sm">
                <table>
                    <thead class="box-experience__title sticky-top" style="z-index: 10">
                        <tr>
                            <th>
                                <span>{{
                                    "words.transaction-id" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.transaction-detail" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.transaction-user" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.transaction-date" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.transaction-status" | translate
                                    }}</span>
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <msc-common-spinner *ngIf="isLoading"></msc-common-spinner>
                    <tbody *ngIf="!isLoading">
                        <ng-container *ngFor="
                                let row of emitedCertificates;
                                let i = index
                            ">
                            <tr>
                                <td>
                                    <i class="icon icon-eye ml-5 mr-5 cursor-pointer" [tooltip]="
                                            row.xGenerationId
                                                ? row.xGenerationId
                                                : '-'
                                        "></i>
                                    <i class="icon icon-copy cursor-pointer" (click)="copyId(row.xGenerationId)"
                                        [tooltip]="
                                            'words.copy-clipboard' | translate
                                        "></i>
                                </td>
                                <td>
                                    <span class="clamp-2">{{
                                        row.xGenerationMessage | translate
                                        }}</span>
                                </td>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.email">{{ row.email | lowercase }}</span>
                                </td>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.createdAt">{{ row.createdAt }}</span>
                                </td>
                                <td>
                                    <div *ngIf="
                                            row.status == 'words.status-pending'
                                        " class="w-24 p-1 rounded-lg min-h-9 pending text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-sended'
                                        " class="w-24 p-1 rounded-lg min-h-9 sended text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-failed'
                                        " class="w-24 p-1 rounded-lg min-h-9 failed text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                </td>

                                <td>
                                    <div class="td-buttons">
                                        <ng-container *ngIf="
                                                row.status ==
                                                'words.status-pending'
                                            ">
                                            <msc-button class="btn-opt-1 z-0 mr-3" icon="icon-edit" palette="secondary"
                                                [tooltip]="
                                                    'common.edit' | translate
                                                " (click)="openModalEdit(row)">
                                            </msc-button>
                                            <msc-button class="btn-opt-1 z-0 mr-3" icon="icon-paper-plane"
                                                palette="secondary" [tooltip]="
                                                    'words.transaction-send'
                                                        | translate
                                                " (click)="
                                                    sendTransaction(row.id)
                                                " [disabled]="isLoadingSend">
                                            </msc-button>
                                        </ng-container>
                                    </div>
                                </td>
                                <td>

                                    <label for="collapse-toggle-{{ i }}" class="btn-collapse-arrow">
                                        <i class="icon icon-arrow-ios-down"></i>
                                    </label>
                                </td>
                            </tr>

                            <!-- Nueva fila para el collapse -->
                            <tr *ngIf="i === i" [class.collapsed]="!row.isCollapsed">
                                <td colspan="9" class="m-0 p-0 border-0 pb-4">
                                    <input type="checkbox" id="collapse-toggle-{{ i }}" class="collapse-toggle" hidden
                                        [(ngModel)]="row.isCollapsed" />
                                    <div class="collapse my-2" id="collapse-toggle-{{ i }}">
                                        <div class="card-body pb-4">
                                            <span class="pb-1">{{
                                                "words.dynamic-data-information"
                                                | translate
                                                }}</span>
                                            <br>
                                            <span style="display: block">{{
                                                "words.transaction-template-name"
                                                | translate
                                                }}:
                                                {{ row.templateName }}
                                            </span>
                                            <span style="display: block">{{
                                                "words.experience-name"
                                                | translate
                                                }}: {{ row.experienceName }}
                                            </span>
                                            <span *ngFor="
                                                    let fields of row.dynamicDataF
                                                " style="display: block">{{ fields.field }} :
                                                {{ fields.value }}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!--pagination-->
            <nav aria-label="Page navigation my-4" [ngClass]="{ 'opacity-0 pointer-events-none': isLoading }"
                class="flex justify-center nav-pg">
                <ul class="flex items-center -space-x-px h-8 text-sm pt-4 mt-1">
                    <li class="paginationItemRounded" [tooltip]="'word.to.first' | translate"
                        [class.disabled]="currentPage === 1">
                        <a class="rounded-start text-gray-500 paginationItem flex items-center justify-center px-3 h-8 hover:bg-gray-100 hover:text-gray-700 leading-tight border  border-gray-300"
                            [class.cursor-not-allowed]="currentPage === 1" (click)="currentPage > 1 && setPaginator(1)"
                            style="border-radius: 8px 0px 0px 8px;">
                            <div class="icon-arrow-group">
                                <i class="icon icon-arrow-ios-left-1"></i>
                                <i class="icon icon-arrowhead-left"></i>
                            </div>
                        </a>
                    </li>

                    <li *ngIf="paginationItems[0] !== 1">
                        <span></span>
                    </li>
                    <!-- Botón "Anterior" -->
                    <li [tooltip]="'word.previous.to.ten' | translate" (click)="
                            totalPages > 10 && changePageAdvance10('previous')
                        " [class.disabled]="currentPage <= 10">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="currentPage <= 10">
                            <i class="icon icon-arrowhead-left"></i>
                        </a>
                    </li>

                    <li [tooltip]="'word.to.previous' | translate" (click)="changePage('previous')"
                        [class.disabled]="currentPage !== 1">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="currentPage === 1">
                            <span class="sr-only">Previous</span>
                            <i class="icon-arrow-ios-left-1"></i>
                        </a>
                    </li>

                    <!-- Páginas -->
                    <li *ngFor="let item of paginationItems">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
                            [ngClass]="{
                                'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white':
                                    currentPage !== item,
                                'z-10 text-blue-600 border-blue-300 bg-blue-50':
                                    currentPage === item
                            }" (click)="setPaginator(item)">
                            {{ item }}
                        </a>
                    </li>

                    <!-- Botón "Siguiente" -->
                    <li [tooltip]="'word.to.next' | translate" [class.disabled]="currentPage === totalPages"
                        (click)="currentPage < totalPages && changePage('next')">
                        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            [class.cursor-not-allowed]="currentPage === totalPages">
                            <span class="sr-only">Next</span>
                            <i class="icon-arrow-ios-right-1"></i>
                        </a>
                    </li>

                    <li [tooltip]="'word.next.to.ten' | translate" [class.disabled]="currentPage === totalPages"
                        (click)="totalPages > 10 && changePageAdvance10('next')">
                        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="
                                currentPage === totalPages
                            ">
                            <i class="icon icon-arrowhead-right"></i>
                        </a>
                    </li>

                    <li class="paginationItemRounded" [tooltip]="'word.to.last' | translate"
                        [class.disabled]="currentPage === totalPages">
                        <a style="border-radius: 0px 8px 8px 0px;" class=" border-gray-300 rounded-end text-gray-500 roundeyztyuuyd-end paginationItem flex items-center justify-center px-3 h-8 hover:bg-gray-100 hover:text-gray-700 leading-tight border current-{{
                                currentPage
                            }} toral-{{ totalPages }}" [class.disabled]="totalPages < 10" [class.cursor-not-allowed]="
                                currentPage === totalPages
                            " (click)="
                                currentPage !== totalPages && goToLastPage()
                            ">
                            <div class="icon-arrow-group">
                                <i class="icon icon-arrow-ios-right-1"></i>
                                <i class="icon icon-arrowhead-right"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!--edit modal-->
<msc-common-modal size="30vw" #modalEdition [title]="'words.transaction-edit' | translate">
    <ng-template>
        <form [formGroup]="TransactionEditForm" class="transactionEditForm">
            <msc-input [label]="'words.firstname' | translate" [placeholder]="'words.firstname' | translate"
                class="w-full pr-3" formControlName="firstname"></msc-input>

            <msc-input [label]="'words.lastname' | translate" [placeholder]="'words.lastname' | translate"
                class="w-full pr-3" formControlName="lastname"></msc-input>

            <h3>{{ "words.dynamic-data" | translate }}</h3>
            <div class="mt-3 max-h-56 overflow-auto" formArrayName="items">
                <div class="flex mb-3 items-end" *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                    <msc-input [label]="'words.field' | translate" [placeholder]="'words.field' | translate"
                        class="w-full pr-3" formControlName="field"></msc-input>

                    <msc-input [label]="'words.value' | translate" [placeholder]="'words.value' | translate"
                        class="w-full pl-3" formControlName="value"></msc-input>
                </div>
            </div>

            <div class="flex items-center mt-8 space-x-5 justify-start">
                <msc-button class="msc-button--primary mr-3" palette="primary" (click)="updateTransaction()"
                    [disabled]="!TransactionEditForm.valid">
                    {{ "common.edit" | translate }}
                </msc-button>

                <msc-button palette="primary" (click)="modalEdition.onClose()">
                    {{ "common.cancel" | translate }}
                </msc-button>
            </div>
        </form>
    </ng-template>
</msc-common-modal>

<msc-common-modal size="20vw" #modalAdd [title]="'words.template-delete-title' | translate">
    <ng-template>
        <div></div>
        <span>{{ "words.template-delete-text" | translate }}</span>

        <div class="flex items-center mt-3 space-x-5 justify-start">
            <msc-button class="msc-button--primary mr-3" palette="primary" (click)="deleteTemplate()">
                {{ "common.create" | translate }}
            </msc-button>

            <msc-button palette="primary" (click)="modalAdd.onClose()">
                {{ "common.cancel" | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>
