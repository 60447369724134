import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company, KnowledgeSkill, KnowledgeProvider, LanguageProvider } from '@lighty';
import { StorageService, ErrorStreamService, TranslateService } from '@services';
import { Language } from '@interfaces';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-organize-knowledge-watch-summary',
    templateUrl: './summary.component.html'
})
export class OrganizeKnowledgeWatchSummaryComponent implements OnInit {
    debounceValue = 2000;
    public company: Company;
    public skill: KnowledgeSkill;
    public languages: Language[];
    public displayAsides: any;

    constructor(
        private route: ActivatedRoute,
        private knowledgeProvider: KnowledgeProvider,
        private languageProvider: LanguageProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.skill = this.storageService.get('skill') || this.route.parent.snapshot.data.skill;

        this.languageProvider.get('interface').subscribe(data => this.languages = data);

        this.displayAsides = {
            forbid: false
        };
    }

    

    updateSkill(): void {
        const params = {
            ...this.skill,
            companyId: this.company.id,
        };

        this.errorStreamService.locked();
        this.knowledgeProvider.update(this.skill.id, params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
            this.errorStreamService.unlocked();
            this.refresh();
        }, () => {
            this.toastService.onError(this.translateService.instant('toast.skill-name-already-used'));
            this.errorStreamService.unlocked();
        });
    }

    openAside(type: string): void {
        this.displayAsides[type] = true;
    }

    closeAside(type: string): void {
        this.displayAsides[type] = false;
    }

    allow(): void {
        this.skill.visible = true;
        this.skill.suggestion = null;
        this.updateSkill();
    }

    refresh(): void {
        this.knowledgeProvider.getSkill(this.skill.id).subscribe((skill) => {
            this.skill = this.storageService.set('skill', skill);
        });
    }
}
