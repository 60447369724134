import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonToastService } from '@common2/services/toast.service';
import { UserProvider, Company, LanguageProvider } from '@lighty';
import { TranslateService, StorageService, ErrorStreamService, ExternalAppsService } from '@services';

import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'msc-account-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
    public company: Company;
    public profile: any;
    public displayPassword: boolean = false;
    public credentials: any;
    public displays: any;
    public error: any = {
        short: false,
        characters: false,
        confirm: false,
        credential: false
    };
    public valid: any = {
        short: false,
        characters: false
    };
    public contentLanguagesSelectedLabel: string;
    public selectData: any = {
        interfaceLanguages: [],
        contentLanguages: []
    }

    constructor(
        private route: ActivatedRoute,
        private userProvider: UserProvider,
        private translateService: TranslateService,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private errorStreamService: ErrorStreamService,
        private externalAppsService: ExternalAppsService,
        private languageProvider: LanguageProvider
    ) { }
    public urlPasswordReset: string | null = null;
    ngOnInit(): void {
        this.profile = this.route.snapshot.data.profile;
        this.company = this.storageService.get('company');
        this.credentials = {
            old_password: '',
            password: '',
            password_confirmation: ''
        };

        this.displays = {
            learningLanguages: false,
            lang: false
        };

        this.profile.learningLanguagesIds = this.profile.learningLanguages.map(lang => lang.id);
        this.contentLanguagesSelectedLabel = this.profile.learningLanguages.map(lang => lang.code).join(', ');
        this.getLanguages();

        // Obtener el tema almacenado en localStorage
        const storedTheme = JSON.parse(localStorage.getItem('theme') || '{}');
        this.urlPasswordReset = storedTheme?.urlPasswordReset || null;
    }

    getLanguages(force?): void {
        const langsObservable = forkJoin({
            interface: this.languageProvider.get('interface', force),
            content: this.languageProvider.get('content', force)
        });

        this.selectData.contentLanguages = [];

        langsObservable
            .pipe(
                tap((resp) => {
                    this.selectData.interfaceLanguages = this.getSelectData(resp.interface, 'interfaceLanguages');
                    this.selectData.contentLanguages = this.getSelectData(resp.content, 'contentLanguages');
                }),
                tap(() => {
                    this.contentLanguagesSelectedLabel = this.selectData.contentLanguages.filter(l => l.isSelected).map(l => l.label).join(', ')
                })
            )
            .subscribe();
    }

    getSelectData(data, key?: string) {
        switch (key) {
            case 'interfaceLanguages':
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: d.id === this.profile.language.id
                }));
            case 'contentLanguages':
                return data.map(d => ({
                    id: d.id,
                    label: d.name,
                    value: d,
                    isSelected: this.profile.learningLanguagesIds.includes(d.id)
                }));
        }
    }

    onSelectOption(option) {
        this.selectData.interfaceLanguages = this.selectData.interfaceLanguages.map(d => {
            d.isSelected = d.id === option.id;
            return d;
        });

        this.setLang(option.value);
    }

    onSelectMultiOption(option) {
        this.selectData.contentLanguages = this.selectData.contentLanguages.map((d) => {
            if (d.id === option.id) {
                d.isSelected = !d.isSelected;
            }
            return d;
        });

        const selectedLanguages = this.selectData.contentLanguages.filter(lang => lang.isSelected);
        this.contentLanguagesSelectedLabel = selectedLanguages.map(lang => lang.label).join(', ');
        this.onSelect(selectedLanguages);
    }

    setLang(language): void {
        this.profile.language = language;
        this.profile.languageId = language.id;
        this.update();
    }

    onSelect(data): void {
        this.profile.learningLanguagesIds = data.map(d => d.id);
        this.update();
    }

    checkPasswordMatch(): void {
        const regNumber = RegExp(/^.*(?=.*[\d\x]).*$/);
        const regUppercase = RegExp(/^.*(?=.*[A-Z]).*$/);
        const regLowercase = RegExp(/^.*(?=.*[a-z]).*$/);

        this.error.confirm = this.credentials.password !== this.credentials.password_confirmation;
        this.error.short = this.credentials.password.length > 0 && this.credentials.password.length < 8;
        this.error.number = !regNumber.test(this.credentials.password);
        this.error.uppercase = !regUppercase.test(this.credentials.password);
        this.error.lowercase = !regLowercase.test(this.credentials.password);

        this.valid.short = this.credentials.password.length >= 8;
        this.valid.number = regNumber.test(this.credentials.password) && this.credentials.password.length > 0;
        this.valid.uppercase = regUppercase.test(this.credentials.password) && this.credentials.password.length > 0;
        this.valid.lowercase = regLowercase.test(this.credentials.password) && this.credentials.password.length > 0;
    }

    update(): void {
        this.userProvider.updateAccount(this.profile, this.company.id).subscribe(() => {
            this.translateService.setLang(this.profile.language.code);
            // this.languageDropdown.getLanguages(this.profile.language); //TODO: get translated languages and update select options
            this.getLanguages(true);

            if (this.externalAppsService.beamer()) {
                this.externalAppsService.beamer().update({ language: this.profile.language.code });
            }
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    save(): void {
        this.errorStreamService.locked();
        const params: any = {
            old_password: this.credentials.old_password,
            password: this.credentials.password,
        };
        this.userProvider.updatePassword(params).subscribe(() => {
            this.toastService.onSuccess(this.translateService.instant('toast.password-updated'));
            this.displayPassword = false;
            this.errorStreamService.unlocked();
            this.error = {
                short: false,
                caracters: false,
                confirm: false,
                credential: false
            };

            this.credentials = {
                old_password: '',
                password: '',
                password_confirmation: ''
            };
        }, (failure) => {
            this.toastService.onError(this.translateService.instant('toast.error-occurred'));
            if (failure.error.message.password) {
                const messages = failure.error.message.password;

                for (const message of messages) {
                    switch (message) {
                        case 'too-short':
                            this.error.short = true;
                            break;
                        case 'num-maj-min':
                            this.error.caracters = true;
                            break;
                    }
                }
            } else if (failure.error.message) {
                const message = failure.error.message;
                switch (message) {
                    case 'wrong credentials':
                        this.error.credential = true;
                        break;
                }
            }
            this.errorStreamService.unlocked();
        });
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    activePassword(): void {
        this.displayPassword = true;
    }
}
