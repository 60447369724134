import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModalComponent } from '@common2/components/modal/modal.component';
import { CommonToastService } from '@common2/services/toast.service';
import { DataHelper } from '@helpers';
import { CompanyCertificatesProvider } from '@lighty';
import { TranslateService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { finalize, take } from 'rxjs';

@Component({
    selector: 'app-generated-certificates',
    templateUrl: './generated-certificates.component.html',
    styleUrls: ['./generated-certificates.component.scss'],
})
export class GeneratedCertificatesComponent implements OnInit {
    @ViewChild('modalDelete') modalDelete: CommonModalComponent;
    public emitedCertificates: any;
    public dateFormat: String;
    public isLoading: boolean = false;
    public isLoadingFilter: boolean = false;
    public filters: Array<any> = [];
    public filteredFilters: Array<any> = [];
    public currentSelectedFilter: any = undefined;
    public currentSelectedFilteredFilters: any = undefined;
    public inputFilter: string = '';
    public paginator: any;
    //public paginationItems: any;
    public currentDeleteItem: any;

    //public currentPage: number = 1;
    //public totalPages: number = 10; // Cambia este valor dinámicamente según tu lógica.
    //public paginationItems: number[] = Array(this.totalPages).fill(0).map((_, i) => i + 1);

    public paginationItems: number[] = []; // Todas las páginas
    public visiblePaginationItems: number[] = []; // Páginas visibles
    public currentPage: number = 1; // Página actual
    public totalPages: number = 0; // Total de páginas
    public total: number = 0; // Total de páginas
    public maxVisiblePages: number = 10; // Número máximo de páginas visibles

    constructor(
        private companyCertificatesProvider: CompanyCertificatesProvider,
        private cookieService: CookieService,
        private toastService: CommonToastService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.getCertificates();
        this.filters = [
            {
                key: this.translateService.instant('words.email'),
                value: 'email',
            },
            {
                key: this.translateService.instant('common.status'),
                value: 'status',
            },
            {
                key: this.translateService.instant('words.experience-name'),
                value: 'experience',
            },
        ];
    }

    public getCertificates(): void {
        const params = {
            page: 1,
        };
        this.isLoading = true;
        this.companyCertificatesProvider
            .getCertificates(this.cookieService.get('current_company'), params)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    this.isLoading = false;
                    this.emitedCertificates = data.data;
                    this.buildPaginator(data.pagination);
                    this.paginator = data.pagination;
                    console.log(this.emitedCertificates);
                },
                error: (err) => {
                    this.isLoading = false;
                    console.log(err);
                },
            });
    }

    formatData(dateFormat: Date): String {
        const date = new Date(dateFormat);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    public openModalDelete(row: any): void {
        this.currentDeleteItem = row;
        this.modalDelete.onOpen();
    }

    public copyLink(id: string): void {
        DataHelper.clipboard(id);
        this.toastService.onInfo(this.translateService.instant('words.copied'));
    }

    public clearFilters(): void {
        this.currentSelectedFilter = undefined;
        this.currentSelectedFilteredFilters = undefined;
        this.inputFilter = '';
        this.getCertificates();
    }

    /*public buildPaginator(paginationInfo: any): void {
      this.paginator = undefined;
      this.paginationItems = [];
      this.paginationItems = Array(paginationInfo.lastPage).fill(0).map((x, i) => i);
      setTimeout(() => {
        const paginationNumbers = document.querySelectorAll('.paginationItem');
        paginationNumbers[0].className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
      }, 100);
    }*/

    /*public setPaginator(index: number, e: any): void {
      console.log('setpaginator')
      const element = e.target as HTMLElement;
      const paginationNumbers = document.querySelectorAll('.paginationItem');
      paginationNumbers.forEach(item => {
        (item as HTMLElement).className = 'paginationItem flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
      });
      element.className = 'paginationItem z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white';
      this.applyPagination(index);
    }*/

    public buildPaginator(paginationInfo: any): void {
        this.paginationItems = [];
        const totalPages = paginationInfo.lastPage;
        this.totalPages = paginationInfo.lastPage;
        const pagesToShow = 10;

        // Calcular el bloque de páginas a mostrar
        let currentBlockStart = Math.max(
            this.currentPage - Math.floor(pagesToShow / 2),
            1
        );
        let currentBlockEnd = Math.min(
            currentBlockStart + pagesToShow - 1 + 1,
            totalPages
        );
        // Ajustar si estamos al final
        if (currentBlockEnd === totalPages) {
            currentBlockStart = Math.max(totalPages - pagesToShow + 1, 1);
        }

        // Ajustar si estamos al principio
        if (currentBlockStart === 1) {
            currentBlockEnd = Math.min(pagesToShow, totalPages);
        }

        // Generar los números de página a mostrar
        this.paginationItems = Array.from(
            { length: currentBlockEnd - currentBlockStart + 1 },
            (_, i) => currentBlockStart + i
        );
    }

    public setPaginatorSingle(page: number, event?: any): void {
        const totalPages = this.paginator?.lastPage || 1;

        // Validar si la página seleccionada es válida
        if (page < 1 || page > totalPages) {
            console.error('Página no válida');
            return;
        }

        // Actualizar la página actual
        this.currentPage = page;

        // Verificar si es necesario mover al siguiente rango de páginas
        const pagesToShow = 10; // Define cuántas páginas se muestran por bloque
        const currentBlockEnd = Math.min(
            Math.floor((this.currentPage - 1) / pagesToShow) * pagesToShow +
                pagesToShow,
            totalPages
        );

        // Mover al siguiente bloque si corresponde
        if (
            this.currentPage === currentBlockEnd &&
            this.currentPage < totalPages
        ) {
            this.buildPaginator({ lastPage: totalPages }); // Llama a buildPaginator con el total de páginas
        }

        // Aplicar la paginación y obtener los datos de la nueva página
        this.applyPagination(this.currentPage);
    }

    public setPaginator(page: number, event?: any): void {
        console.log(page);
        const totalPages = this.paginator?.lastPage || 1;

        // Validar si la página seleccionada es válida
        if (page < 1 || page > totalPages) {
            console.error('Página no válida');
            return;
        }

        // Actualizar la página actual
        this.currentPage = page;

        // Definir cuántas páginas se muestran por bloque
        const pagesToShow = 10;
        const currentBlockStart =
            Math.floor((this.currentPage - 1) / pagesToShow) * pagesToShow + 1;
        const currentBlockEnd = Math.min(
            currentBlockStart + pagesToShow - 1,
            totalPages
        );

        // Actualizar el rango del paginador si corresponde
        this.buildPaginator({
            currentBlockStart,
            currentBlockEnd,
            totalPages,
        });

        // Aplicar la paginación y obtener los datos de la nueva página
        this.applyPagination(this.currentPage);
    }

    public goToLastPage(): void {
        if (this.paginator && this.paginator.lastPage) {
            this.setPaginator(this.paginator.lastPage);
        }
    }
    public changePage(direction: 'previous' | 'next'): void {
        console.log('this.paginator', this.paginator);
        this.totalPages = this.paginator?.total;
        const totalPages = this.paginator?.lastPage;

        if (direction === 'previous' && this.currentPage > 1) {
            this.setPaginator(this.currentPage - 1);
        } else if (direction === 'next' && this.currentPage < totalPages) {
            this.setPaginator(this.currentPage + 1);
        }

        // Actualiza el bloque de páginas si la página actual está fuera del rango actual
        const pagesToShow = 10;
        const currentBlockStart =
            Math.floor((this.currentPage - 1) / pagesToShow) * pagesToShow + 1;
        const currentBlockEnd = Math.min(
            currentBlockStart + pagesToShow - 1,
            totalPages
        );

        if (
            this.currentPage < currentBlockStart ||
            this.currentPage > currentBlockEnd
        ) {
            this.buildPaginator(this.paginator);
        }
    }

    public changePageAdvance10Static(direction: 'previous' | 'next', currentPage?:number): void {
        const totalPages = this.paginator?.lastPage || 1;
        this.totalPages = this.paginator?.total;
        const pagesToShow = 10;

        if (direction === 'previous') {
            console.log(this.currentPage);
            console.log(pagesToShow);
            console.log(((this.currentPage - 1) / pagesToShow));
            console.log(((this.currentPage - 1) / pagesToShow) * pagesToShow);
            console.log(((this.currentPage - 1) / pagesToShow) * pagesToShow - pagesToShow);
            console.log(((this.currentPage - 1) / pagesToShow) * pagesToShow - pagesToShow +1);

            // Ir al bloque anterior
            const previousBlockStart = Math.max(
                Math.floor((this.currentPage - 1) / pagesToShow) * pagesToShow -
                    pagesToShow +
                    1,
                1
            );

            // Si no estamos ya en el bloque anterior, vamos al inicio del bloque
            if (this.currentPage > previousBlockStart) {
                this.setPaginator(previousBlockStart);
            }
        } else if (direction === 'next') {
            // Ir al siguiente bloque
            const nextBlockStart = Math.min(
                Math.floor((this.currentPage - 1) / pagesToShow) * pagesToShow +
                    pagesToShow +
                    1,
                totalPages
            );

            if (this.currentPage < nextBlockStart) {
                this.setPaginator(nextBlockStart);
            }
        }
    }

    public changePageAdvance10(direction: 'previous' | 'next'): void {
        const totalPages = this.paginator?.lastPage || 1;
        const pagesToShow = 10;

        if (direction === 'previous') {
            // Retrocede exactamente 10 páginas, pero no menos de la página 1
            const newPage = Math.max(this.currentPage - pagesToShow, 1);
            this.setPaginator(newPage);
        } else if (direction === 'next') {
            // Avanza exactamente 10 páginas, pero no más que la última página
            const newPage = Math.min(this.currentPage + pagesToShow, totalPages);
            this.setPaginator(newPage);
        }
    }


    public applyPagination(pageIndex: number): void {
        // Muestra un indicador de carga mientras se obtienen los datos
        this.isLoading = true;

        // Construye los parámetros de la solicitud con base en la página actual
        let params: any = {
            page: pageIndex,
        };

        // Si hay filtros aplicados, los agrega a los parámetros
        if (
            this.currentSelectedFilter &&
            (this.currentSelectedFilteredFilters || this.inputFilter)
        ) {
            params.filter = this.currentSelectedFilter?.value || '';
            params.value =
                this.inputFilter === ''
                    ? this.currentSelectedFilteredFilters?.value
                    : this.inputFilter;
        }
        // Realiza la solicitud al backend
        this.companyCertificatesProvider
            .getCertificatesPaginatedTransactions(
                this.cookieService.get('current_company'),
                params
            )
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false; // Oculta el indicador de carga independientemente del resultado
                })
            )
            .subscribe(
                (data) => {
                    if (data) {
                        this.emitedCertificates = data?.data;
                        this.paginator = data?.pagination; // Actualiza los datos de la paginación
                        this.buildPaginator(this.paginator); // Reconstruye el paginador según los nuevos datos
                    } else {
                        this.paginator = undefined;
                        this.emitedCertificates = [];
                    }
                },
                (error) => {
                    console.error(
                        'Error al obtener los datos de la página:',
                        error
                    );
                }
            );
    }

    public filter(): void {
        this.paginator = undefined;
        this.paginationItems = [];
        this.isLoading = true;
        const params = {
            filter: this.currentSelectedFilter.value,
            value:
                this.inputFilter == ''
                    ? this.currentSelectedFilteredFilters.value
                    : this.inputFilter,
        };
        this.companyCertificatesProvider
            .getCertificatesFilteredTransactions(
                this.cookieService.get('current_company'),
                params
            )
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.isLoading = false;
                    if (data) {
                        this.emitedCertificates = data?.data;
                        this.buildPaginator(data?.pagination);
                        this.paginator = data?.pagination;
                        //console.log('paginator', this.paginator)
                    } else {
                        this.paginator = undefined;
                        this.emitedCertificates = [];
                    }
                },
                (error) => {
                    this.isLoading = false;
                    console.log(error);
                }
            );
    }

    public onSelectType(filter: any, origin: any): void {
        if (origin == 'filter') {
            this.currentSelectedFilter = filter;
            this.currentSelectedFilteredFilters = undefined;
            if (filter.value != 'email') {
                this.inputFilter = '';
                this.isLoadingFilter = true;
                const params = {
                    filter: this.currentSelectedFilter.value,
                };
                this.companyCertificatesProvider
                    .getFilterCertificates(params)
                    .pipe(take(1))
                    .subscribe({
                        next: (data) => {
                            this.isLoadingFilter = false;
                            console.log(data);
                            this.filteredFilters = data.data;
                        },
                        error: (err) => {
                            this.isLoading = false;
                            this.isLoadingFilter = false;
                            console.log(err);
                        },
                    });
            }
        } else {
            this.currentSelectedFilteredFilters = filter;
        }
    }

    public revokeCerficate(): void {
        const params = {
            xertify_transaction: this.currentDeleteItem.id,
        };
        this.companyCertificatesProvider
            .revokeCertificate(params)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    this.getCertificates();
                    this.modalDelete.onClose();
                    this.toastService.onSuccess(
                        this.translateService.instant(data.message)
                    );
                    console.log(data);
                },
                error: (err) => {
                    console.log(err);
                    this.toastService.onSuccess(
                        this.translateService.instant(err.error.error)
                    );
                },
            });
    }

    public downloadCertificate(certificate: any): void {
        const params = {
            transaction_id: certificate.id,
        };
        this.companyCertificatesProvider
            .downloadCertificate(params, certificate.course)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    console.log(data);
                },
                error: (err) => {
                    console.log(err);
                },
            });
    }

    public sendCertificate(certificateId: any): void {
        this.companyCertificatesProvider
            .sendCertificate(certificateId)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    console.log(data);
                    this.toastService.onSuccess(
                        this.translateService.instant(data.message)
                    );
                },
                error: (err) => {
                    console.log(err);
                },
            });
    }
}
