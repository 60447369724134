<section *ngIf="hasError" class="error-state">
    <h2>Error 404</h2>
    <p>El contenido que buscas no está disponible.</p>
</section>

<section *ngIf="!hasError">
    
    <section class="body-miscursos">
        <section class="head head-miscursos">
            <msc-shared-topbar-filters
                [type]="'content'"
                [(query)]="query"
                [(mode)]="mode"
                [(filters)]="filters"
                (queryChange)="search()"
                (filtersChange)="search()"
            >
            </msc-shared-topbar-filters>
        </section>
    
        <section
            *ngIf="mode === 'list'"
            class="body"
            infinite-scroll
            [scrollWindow]="false"
            [infiniteScrollThrottle]="500"
            (scrolled)="loadMore()"
            infiniteScrollTrigger
            [items]="contents"
        >
            <div
                class="card-container"
                *ngIf="mode === 'list' && !searching && contents"
            >
                <ng-container *ngFor="let content of contents; let index = index">
                    <content-card
                        [content]="content"
                        (onDelete)="$event ? '' : deleteContent(index)"
                    >
                    </content-card>
                </ng-container>
            </div>
    
            <msc-empty-state
                *ngIf="contents?.length === 0"
                [title]="'empty.content.search.title'"
                [description]="'empty.content.search.description'"
                [cypressHook]="'contents'"
            >
            </msc-empty-state>
    
            
        </section>
    
        <section
            *ngIf="mode === 'calendar'"
            class="body"
            infinite-scroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="500"
            (scrolled)="loadMoreEvents()"
            infiniteScrollTrigger
            [items]="events"
        >
            <div class="container-contents__list row animate" *ngIf="!searching">
                <msc-shared-event-calendar
                    [type]="'content'"
                    [events]="events"
                ></msc-shared-event-calendar>
    
                <msc-empty-state
                    *ngIf="events?.length === 0"
                    [title]="'empty.content.search.title'"
                    [description]="'empty.content.search.description'"
                    [cypressHook]="'calendar'"
                >
                </msc-empty-state>
            </div>
            <div
                class="container-catalog-internal__list row animate"
                *ngIf="mode === 'calendar'"
            >
                <msc-shared-event-calendar
                    [type]="'catalogue'"
                    [events]="events"
                ></msc-shared-event-calendar>
            </div>
        </section>
        <div
            class="container-catalog-internal__more col-12 col-xxs-12 flex justify-center"
        >
            <msc-load-more
                *ngIf="!searching"
                [loading]="loading"
                [pagination]="pagination"
                (onLoad)="loadMore()"
            ></msc-load-more>
        </div>
    </section>
</section>
