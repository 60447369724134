<div class="modal" [class.active]="active">
    <div class="modal__overlay"></div>

    <div class="modal__content">
        <div class="modal__header">
            <div class="modal__close" (click)="close()">
                <i class="icon icon-close"></i>
            </div>

            <h2 class="modal__title">
                {{ "stats.email-export.title" | translate }}
            </h2>
        </div>
        <!--DATE
        <div
            class="w-full content-stats-adoption-date content-stats-adoption__search flex align-center gap-4"
            *ngIf="options.type == 'learner-overview' || options.type == 'learner-engagement'"
        >
            <h3>{{ "common.last" | translate }}</h3>
        </div>-->
        <ng-container *ngIf="options.type == 'learner-overview' || options.type == 'learner-engagement'">
            <!--
            <div
            class="w-full content-stats-adoption-date content-stats-adoption__search flex align-center gap-4"
        >
            <msc-common2-radio
                groupName="rangeExport"
                [(ngModel)]="data.range"
                [valueName]="1"
                [label]="'common.one-month' | translate"
                (click)="displayRange(false)"
            >
            </msc-common2-radio>
            <msc-common2-radio
                groupName="rangeExport"
                [(ngModel)]="data.range"
                [valueName]="3"
                [label]="'common.tree-months' | translate"
                (click)="displayRange(false)"
            >
            </msc-common2-radio>
            <msc-common2-radio
                groupName="rangeExport"
                [(ngModel)]="data.range"
                [valueName]="6"
                [label]="'common.six-months' | translate"
                (click)="displayRange(false)"
            >
            </msc-common2-radio>
            <msc-common2-radio
                groupName="rangeExport"
                [(ngModel)]="setRange"
                [valueName]="'P'"
                [label]="'words.custom' | translate"
            >
            </msc-common2-radio>
            <msc-common2-radio
                groupName="rangeExport"
                [(ngModel)]="data.range"
                [valueName]="'A'"
                [label]="'words.all' | translate"
                (click)="displayRange(false)"
            >
            </msc-common2-radio>
            </div>-->
        <div
            *ngIf="setRange"
            class="w-full content-stats-adoption-date content-stats-adoption__search flex align-center gap-4 mt-4"
        >
            <div class="date-till flex align-center justify-start">
                <p>{{ "words.from-the" | translate }}</p>

                <div
                    class="select"
                    [ngClass]="{ active: displays.date_from }"
                    (click)="displayed('date_from')"
                >
                    <div class="select__content">
                        <p class="select__placeholder">
                            {{ dates.from | date : "dd MMM YYYY" }}
                            <i class="icon icon-arrow-ios-down"></i>
                        </p>

                        <div
                            class="select__dropdown"
                            (click)="$event.stopPropagation()"
                        >
                            <msc-datepicker
                                #dateFrom
                                [date]="dates.from"
                                [limit]="{ max: today }"
                                [actions]="true"
                                (onUpdate)="updateDateFrom($event)"
                                (onClose)="displayed('date_from', true)"
                            ></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="date-till flex align-center justify-start">
                <p>{{ "words.up-to" | translate }}</p>

                <div
                    class="select"
                    [ngClass]="{ active: displays.date_to }"
                    (click)="displayed('date_to')"
                >
                    <div class="select__content">
                        <p class="select__placeholder">
                            {{ dates.upto | date : "dd MMM YYYY" }}
                            <i class="icon icon-arrow-ios-down"></i>
                        </p>

                        <div
                            class="select__dropdown"
                            (click)="$event.stopPropagation()"
                        >
                            <msc-datepicker
                                #dateTo
                                [date]="dates.upto"
                                [limit]="{ min: dates.from, max: today }"
                                [actions]="true"
                                (onUpdate)="updateDateTo($event)"
                                (onClose)="displayed('date_to', true)"
                            ></msc-datepicker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
        <div class="flex flex-col space-y-4">
            <div>
                <p
                    class="mb-3 mt-4"
                    [innerHtml]="
                        'stats.email-export.label'
                            | translate : { name: exportName }
                    "
                ></p>
<!-- 
                <div #emailsContainer class="max-h-80 overflow-y-auto">
                    <div
                        class="flex items-center mb-2"
                        *ngFor="let emailField of data.emails; let i = index"
                    >
                        <div class="w-full relative">
                            <div>
                                <msc-input
                                    *ngIf="i == 0"
                                    type="text"
                                    [disabled]="i === 0"
                                    [hasErrors]="!emailField.isValid"
                                    (focusout)="isValidEmail(emailField)"
                                    [(ngModel)]="emailField.value"
                                ></msc-input>
                            </div>
                             <div
                                *ngIf="i > 1"
                                class="flex flex-col absolute right-0 top-0 px-2 h-full justify-center"
                            >
                                <span
                                    class="cursor-pointer"
                                    (click)="removeEmailField(i)"
                                    ><i class="icon icon-close"></i
                                ></span>
                            </div> 
                        </div>
                    </div>
                </div>-->

                <div #emailsContainer class="max-h-80 overflow-y-auto">
                    <div class="flex items-center mb-2" *ngFor="let emailField of data.emails; let i = index">
                        <div class="w-full relative">
                            <msc-input type="text" [disabled]="i === 0" [hasErrors]="!emailField.isValid"
                                (focusout)="isValidEmail(emailField)" [(ngModel)]="emailField.value"></msc-input>
                            <div *ngIf="i > 0" class="flex flex-col absolute right-0 top-0 px-2 h-full justify-center">
                                <span class="cursor-pointer" (click)="removeEmailField(i)"><i
                                        class="icon icon-close"></i></span>
                            </div>
                        </div>
                    </div>
                </div>


                 <div
                    class="flex items-center text-indigo-600 cursor-pointer w-fit"
                    (click)="addEmailField()"
                >
                    <i class="icon icon-plus-circle-1 text-lg mr-1"></i>
                    <small>{{
                        "stats.email-export.add_email" | translate
                    }}</small>
                </div> 
            </div>
          <!--  <div>
                <p class="mb-2">{{ "words.format" | translate }}</p>
                <div>
                    <msc-common2-radio
                        groupName="dataFormat"
                        [(ngModel)]="data.format"
                        [valueName]="'xlsx'"
                        [label]="'XLSX'"
                    >
                    </msc-common2-radio>
                    <msc-common2-radio
                        groupName="dataFormat"
                        [(ngModel)]="data.format"
                        [valueName]="'csv'"
                        [label]="'CSV'"
                    >
                    </msc-common2-radio>
                </div>
            </div>  -->

            <!-- <div>
                <p class="mb-2">{{ "words.granularity" | translate }}</p>
                <div
                    class="multi-select multi-select--single w-3/12"
                    [ngClass]="{ active: displays.granularity }"
                    (click)="displayed('granularity')"
                >
                    <div class="multi-select__content select">
                        {{ "words." + data.granularity | translate }}
                    </div>

                    <div
                        class="multi-select__data"
                        [(autoClose)]="displays.granularity"
                        *ngIf="displays.granularity"
                    >
                        <div class="data-list">
                            <div
                                class="data-list__item"
                                (click)="change('granularity', 'day')"
                            >
                                <p>
                                    {{ "words.day" | translate }}
                                </p>
                            </div>
                            <div
                                class="data-list__item"
                                (click)="change('granularity', 'week')"
                            >
                                <p>
                                    {{ "words.week" | translate }}
                                </p>
                            </div>
                            <div
                                class="data-list__item"
                                (click)="change('granularity', 'month')"
                            >
                                <p>
                                    {{ "words.month" | translate }}
                                </p>
                            </div>
                            <div
                                class="data-list__item"
                                (click)="change('granularity', 'year')"
                            >
                                <p>
                                    {{ "words.year" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="flex justify-center">
                <msc-button
                    class="msc-button--primary"
                    palette="primary"
                    [disabled]="invalid"
                    (click)="save()"
                    >{{
                        "stats.email-export.send_report" | translate
                    }}</msc-button
                >
            </div>
        </div>
    </div>
</div>
<msc-filters-modal
    #modalFilters
    [filters]="filters"
    (saveEvent)="onFilterSave($event)"
></msc-filters-modal>
